import React from "react";

import "./Contact.css";

interface PropTypes {}

interface StateTypes {
  disable: boolean;
  email: string;
  message: string;
  name: string;
  result: {
    message: string;
    status: string;
  };
}

const INITIAL_STATE: StateTypes = {
  disable: false,
  email: "",
  message: "",
  name: "",
  result: {
    message: "",
    status: "",
  },
};

class ContactForm extends React.Component<PropTypes, StateTypes> {
  state = INITIAL_STATE;

  formRef = React.createRef<HTMLFormElement>();

  componentDidMount() {
    (window as any).emailjs.init("user_OgoZVCfKZGiJ8eRWZtPLt");
  }

  handleChange = ({ currentTarget }: any) => {
    this.setState((state) => ({
      ...state,
      [currentTarget.name]: currentTarget.value,
    }));
  };

  cleanFormFeedback = () => {
    setTimeout(() => {
      this.setState((state) => ({
        ...state,
        result: {
          message: "",
          status: "",
        },
      }));
    }, 5000);
  };

  handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const { email, message, name } = this.state;

    this.setState((state) => ({
      ...state,
      disable: true,
    }));

    try {
      await (window as any).emailjs.send("mailgun", "jl7h924", {
        from_name: name,
        message_html: message,
        reply_to: email,
      });

      this.setState(
        {
          ...INITIAL_STATE,
          disable: false,
          result: {
            message: "Message sent successfully, soon we will contact you",
            status: "success",
          },
        },
        this.cleanFormFeedback
      );
    } catch (error) {
      console.error(error);

      this.setState(
        (state) => ({
          ...state,
          disable: false,
          result: {
            message: "Message could not be sent, try again",
            status: "error",
          },
        }),
        this.cleanFormFeedback
      );
    }
  };

  render() {
    const { disable, email, message, name, result } = this.state;

    return (
      <div className="contact" id="contact">
        <div className={`contact-result ${result.status}`}>
          {result.message}
        </div>
        <h2 className="contact-subtitle">Contactanos</h2>
        <form
          className="contact-form"
          id="contactForm"
          method="post"
          onSubmit={this.handleSubmit}
          ref={this.formRef}
        >
          <div className="form-group">
            <input
              autoComplete="off"
              className="form-control required"
              id="name"
              name="name"
              ref="name"
              value={name}
              placeholder="Name"
              type="text"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <input
              autoComplete="off"
              className="form-control required"
              id="email"
              name="email"
              ref="email"
              value={email}
              placeholder="Email"
              type="email"
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <textarea
              autoComplete="off"
              className="form-control required"
              id="message"
              name="message"
              ref="message"
              value={message}
              placeholder="Message"
              rows={5}
              required
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <input
              className="form-send"
              disabled={!!disable.toString()}
              type="submit"
              value="Enviar mensaje"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;

import React, { useCallback, useState } from "react";
import classnames from "classnames";
import { FiMenu, FiX } from "react-icons/fi";

import "./Navbar.css";

export interface Link {
  id: string;
  title: string;
  url: string;
}

interface PropTypes {
  links: Link[];
}

const Navbar = ({ links }: PropTypes) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleToogleMenu = useCallback(() => {
    setMenuOpen(!isMenuOpen);
  }, [isMenuOpen, setMenuOpen]);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className={classnames("navbar", { "navbar--open": isMenuOpen })}>
      {isMenuOpen && (
        <div className="navbar_sidebar_menu">
          <ul className="navbar_sidebar_menu_list">
            {links.map(({ id, title, url }) => (
              <li
                className="navbar_sidebar_menu_list_item"
                key={`navbar-${id}`}
              >
                <a href={url} onClick={closeMenu}>{title}</a>
              </li>
            ))}
          </ul>
        </div>
      )}
      <img
        alt="reshape-logo"
        className="navbar_logo"
        height={64}
        src="/img/logo.png"
        width={182}
      />
      <ul className="navbar_list">
        {links.map(({ id, title, url }) => (
          <li className="navbar_item" key={`navbar-${id}`}>
            <a className="navbar_link" href={url} onClick={closeMenu}>
              {title}
            </a>
          </li>
        ))}
      </ul>
      <button className="navbar_menu" onClick={handleToogleMenu}>
        {isMenuOpen ? (
          <FiX color="#FFF" size={32} />
        ) : (
          <FiMenu color="#FFF" size={32} />
        )}
      </button>
    </nav>
  );
};

export default Navbar;

import React from "react";

import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar, { Link } from "./components/Navbar";
import Section from "./components/Section";

import "./App.css";

const NAVBAR_LINKS: Link[] = [
  {
    id: "purpose",
    title: "Quienes Somos",
    url: "#purpose",
  },
  {
    id: "services",
    title: "Servicios",
    url: "#services",
  },
  {
    id: "products",
    title: "COMVA",
    url: "#products",
  },
  {
    id: "contact",
    title: "Contacto",
    url: "#contact",
  },
];

const App = () => (
  <>
    <Navbar links={NAVBAR_LINKS} />
    <main>
      <div className="section">
        <Hero image="/img/img1.jpg" />
      </div>
      <div className="section">
        <Section id="purpose" image="/img/metting.jpg">
          <div className="slider_content">
            <h1 className="slider_title">¿Quienes Somos?</h1>
            <h3>Nuestro propósito y nuestros valores</h3>
            <p>
              Somos un equipo de profesionales con un claro propósito: Ser un
              aliado estratégico confiable para nuestros clientes apoyándolos en
              el logro de sus objetivos, a través del acompañamiento en la
              cocreación de las mejores estrategias y soluciones tecnológicas
              para que el negocio sea productivo, rentable y sostensible.
            </p>
            <h3>Nuestra experiencia</h3>
            <p>
              Nuestros socios fundadores poseen más de 25 años de experiencia
              como ejecutivos de organizaciones financieras, así como en grandes
              firmas de consultoría globales, incluyendo las Big Four. También
              nos acompañan como socios, jóvenes profesionales especializados en
              tecnología que han desarrollado soluciones en empresas de alcance
              mundial.
            </p>
          </div>
        </Section>
      </div>
      <div className="section">
        <Section id="services" image="/img/working.jpg" flipped>
          <div className="slider_content">
            <h1 className="slider_title">Servicios</h1>
            <h3>Consultoría</h3>
            <ul>
              <li>Gobierno Corporativo</li>
              <li>Gestión Integral de Riesgos</li>
              <li>
                Prevención de Lavado de Dinero y Financiamiento al Terrorismo
              </li>
              <li>Prevención de Fraudes</li>
              <li>Auditoría Interna</li>
              <li>Capacitación profesional personalizada</li>
              <li>Virtual Adviser</li>
            </ul>
          </div>
        </Section>
      </div>
      <div className="section">
        <Section id="products" image="/img/imgcomva.jpg">
          <div className="slider_content">
            <h1 className="slider_title">COMVA</h1>
            <h3>Rsik Managment Solution</h3>
            <p>
              Automatiza el Ciclo de Gestión de Riesgos vinculado a los procesos
              de negocios desde la perspectiva de Riesgos Operacionales,
              Cumplimiento, Control Interno y Auditoría; construyendo sólidas
              bases para la Resiliencia Operativa de Instituciones Financieras y
              No Financieras. Habilita un efectivo alineamiento de Las Líneas de
              Prevención y Defensa de las organizaciones
            </p>
          </div>
        </Section>
      </div>
      <div className="section">
        <Contact />
      </div>
      <div className="section section-footer">
        <Footer onMoveToTop={() => (window as any).scrollTo(1, 0)} />
      </div>
    </main>
  </>
);

export default App;

import React from "react";

import "./Footer.css";

interface PropTypes {
  onMoveToTop: () => void;
}

const Footer = ({ onMoveToTop }: PropTypes) => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-row">
          <div className="footer-column footer-logo">
            <img alt="reshape-logo" src="/img/logo.png"></img>
          </div>
          <div className="footer-column">
            <p className="footer-heading">Servicios</p>
            <a className="footer-link" href="#services">
              Consultoria
            </a>
          </div>
          <div className="footer-column">
            <p className="footer-heading">Productos</p>
            <a className="footer-link" href="#products">
              COMVA
            </a>
          </div>
          <div className="footer-column">
            <p className="footer-heading">Contacto</p>
            <a className="footer-link" href="#contact">
              LinkedIn
            </a>
          </div>
        </div>
      </div>
      <button className="move-to-top" onClick={onMoveToTop}>
        <img src="/img/arrow.png" alt="move-to-top" />
      </button>
    </footer>
  );
};
export default Footer;

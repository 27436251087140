import React from "react";

import "./Hero.css";

interface PropTypes {
  image: string;
}

const Hero = ({ image }: PropTypes) => (
  <>
    <img alt="Think" className="hero_image" src={image}/>
    <h1 className="hero_title">Soluciones de valor que apoyen</h1>
    <h1 className="hero_title2"> sus estrategias de negocios</h1>
  </>
);

export default Hero;

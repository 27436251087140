import React from "react";
import { useInView } from "react-intersection-observer";

import "./Section.css";

interface PropTypes {
  children: React.ReactNode;
  flipped?: boolean;
  id: string,
  image: string;
}

const Section = ({ children, flipped, id, image }: PropTypes) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.4,
  });

  return (
    <section
      className={`slider ${inView ? "slider--zoom" : ""}`}
      id={id}
      ref={ref}
    >
      {!flipped ? (
        <>
          {children}
          <img src={image} alt="slider" className="slider_image" />
        </>
      ) : (
        <>
          <img src={image} alt="slider" className="slider_image" />
          {children}
        </>
      )}
    </section>
  );
};

export default Section;
